.tw-hidden {
  display: none;
}

@media (min-width: 600px) {
  .sm\:tw-hidden {
    display: none !important;
  }
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.4rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.4rem * var(--tw-space-x-reverse));
  margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.6rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.6rem * var(--tw-space-x-reverse));
  margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.8rem * var(--tw-space-y-reverse));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.8rem * var(--tw-space-x-reverse));
  margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.2rem * var(--tw-space-y-reverse));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.2rem * var(--tw-space-x-reverse));
  margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.6rem * var(--tw-space-y-reverse));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.6rem * var(--tw-space-x-reverse));
  margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.4rem * var(--tw-space-y-reverse));
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.4rem * var(--tw-space-x-reverse));
  margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.8rem * var(--tw-space-y-reverse));
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.8rem * var(--tw-space-x-reverse));
  margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.2rem * var(--tw-space-y-reverse));
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.2rem * var(--tw-space-x-reverse));
  margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.6rem * var(--tw-space-y-reverse));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4.8rem * var(--tw-space-y-reverse));
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4.8rem * var(--tw-space-x-reverse));
  margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6.4rem * var(--tw-space-x-reverse));
  margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}

.divide-blue-lighter > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(188, 222, 250, var(--tw-divide-opacity));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 52, 47, var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 193, 114, var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 144, 220, var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(184, 194, 204, var(--tw-bg-opacity));
}

.bg-grey-light {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 225, 231, var(--tw-bg-opacity));
}

.bg-grey-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.bg-red-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 31, 26, var(--tw-bg-opacity));
}

.bg-red-light {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 87, 83, var(--tw-bg-opacity));
}

.bg-red-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 172, 170, var(--tw-bg-opacity));
}

.bg-red-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 235, 234, var(--tw-bg-opacity));
}

.bg-orange-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(222, 117, 31, var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 153, 63, var(--tw-bg-opacity));
}

.bg-orange-light {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 173, 99, var(--tw-bg-opacity));
}

.bg-orange-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 217, 182, var(--tw-bg-opacity));
}

.bg-orange-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 235, var(--tw-bg-opacity));
}

.bg-yellow-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 208, 36, var(--tw-bg-opacity));
}

.bg-yellow-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 194, var(--tw-bg-opacity));
}

.bg-green-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 157, 85, var(--tw-bg-opacity));
}

.bg-green-light {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 216, 138, var(--tw-bg-opacity));
}

.bg-green-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 245, 191, var(--tw-bg-opacity));
}

.bg-green-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 252, 236, var(--tw-bg-opacity));
}

.bg-teal-light {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 213, 202, var(--tw-bg-opacity));
}

.bg-teal-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 255, 254, var(--tw-bg-opacity));
}

.bg-blue-light {
  --tw-bg-opacity: 1;
  background-color: rgba(108, 178, 235, var(--tw-bg-opacity));
}

.bg-blue-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 222, 250, var(--tw-bg-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 248, 255, var(--tw-bg-opacity));
}

.bg-indigo-light {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 134, 215, var(--tw-bg-opacity));
}

.bg-indigo-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 232, 255, var(--tw-bg-opacity));
}

.bg-purple-light {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 121, 233, var(--tw-bg-opacity));
}

.bg-purple-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 187, 252, var(--tw-bg-opacity));
}

.bg-purple-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 235, 255, var(--tw-bg-opacity));
}

.bg-pink-light {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 126, 168, var(--tw-bg-opacity));
}

.bg-pink-lightest {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 239, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:bg-grey-lightest:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.hover\:bg-orange-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 217, 182, var(--tw-bg-opacity));
}

.hover\:bg-green-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 245, 191, var(--tw-bg-opacity));
}

.hover\:bg-blue-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 222, 250, var(--tw-bg-opacity));
}

.hover\:bg-blue-lightest:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 248, 255, var(--tw-bg-opacity));
}

.hover\:bg-purple-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 187, 252, var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}

.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}

.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgba(227, 52, 47, var(--tw-border-opacity));
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(56, 193, 114, var(--tw-border-opacity));
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgba(52, 144, 220, var(--tw-border-opacity));
}

.border-purple {
  --tw-border-opacity: 1;
  border-color: rgba(149, 97, 226, var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(184, 194, 204, var(--tw-border-opacity));
}

.border-grey-light {
  --tw-border-opacity: 1;
  border-color: rgba(218, 225, 231, var(--tw-border-opacity));
}

.border-grey-lightest {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}

.border-grey-border {
  border-color: rgba(0, 0, 0, 0.12);
}

.border-grey-border-dark {
  border-color: rgba(0, 0, 0, 0.23);
}

.border-cardapio-web {
  --tw-border-opacity: 1;
  border-color: rgba(137, 0, 0, var(--tw-border-opacity));
}

.border-red-dark {
  --tw-border-opacity: 1;
  border-color: rgba(204, 31, 26, var(--tw-border-opacity));
}

.border-red-light {
  --tw-border-opacity: 1;
  border-color: rgba(239, 87, 83, var(--tw-border-opacity));
}

.border-red-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(249, 172, 170, var(--tw-border-opacity));
}

.border-orange-dark {
  --tw-border-opacity: 1;
  border-color: rgba(222, 117, 31, var(--tw-border-opacity));
}

.border-orange-light {
  --tw-border-opacity: 1;
  border-color: rgba(250, 173, 99, var(--tw-border-opacity));
}

.border-orange-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(252, 217, 182, var(--tw-border-opacity));
}

.border-green-dark {
  --tw-border-opacity: 1;
  border-color: rgba(31, 157, 85, var(--tw-border-opacity));
}

.border-green-light {
  --tw-border-opacity: 1;
  border-color: rgba(81, 216, 138, var(--tw-border-opacity));
}

.border-green-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(162, 245, 191, var(--tw-border-opacity));
}

.border-blue-dark {
  --tw-border-opacity: 1;
  border-color: rgba(39, 121, 189, var(--tw-border-opacity));
}

.border-blue-light {
  --tw-border-opacity: 1;
  border-color: rgba(108, 178, 235, var(--tw-border-opacity));
}

.border-blue-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(188, 222, 250, var(--tw-border-opacity));
}

.border-indigo-light {
  --tw-border-opacity: 1;
  border-color: rgba(120, 134, 215, var(--tw-border-opacity));
}

.border-indigo-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(178, 183, 255, var(--tw-border-opacity));
}

.border-purple-light {
  --tw-border-opacity: 1;
  border-color: rgba(167, 121, 233, var(--tw-border-opacity));
}

.border-purple-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(214, 187, 252, var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.hover\:border-cardapio-web:hover {
  --tw-border-opacity: 1;
  border-color: rgba(137, 0, 0, var(--tw-border-opacity));
}

.hover\:border-blue-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgba(39, 121, 189, var(--tw-border-opacity));
}

.border-opacity-80 {
  --tw-border-opacity: 0.8;
}

.rounded-2 {
  border-radius: .2rem;
}

.rounded-4 {
  border-radius: .4rem;
}

.rounded-6 {
  border-radius: .6rem;
}

.rounded-8 {
  border-radius: .8rem;
}

.rounded-12 {
  border-radius: 1.2rem;
}

.rounded-16 {
  border-radius: 1.6rem;
}

.rounded-28 {
  border-radius: 2.8rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: .8rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-l-6 {
  border-top-left-radius: .6rem;
  border-bottom-left-radius: .6rem;
}

.rounded-t-8 {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem;
}

.rounded-b-8 {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem;
}

.rounded-b-12 {
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
}

.rounded-b-28 {
  border-bottom-right-radius: 2.8rem;
  border-bottom-left-radius: 2.8rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-t-1 {
  border-top-width: 1px;
}

.border-r-1 {
  border-right-width: 1px;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.border-l-1 {
  border-left-width: 1px;
}

.border-l-3 {
  border-left-width: 3px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.font-300 {
  font-weight: 300;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.h-0 {
  height: 0;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 0.4rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 0.8rem;
}

.h-9 {
  height: 2.25rem;
}

.h-12 {
  height: 1.2rem;
}

.h-16 {
  height: 1.6rem;
}

.h-20 {
  height: 2rem;
}

.h-24 {
  height: 2.4rem;
}

.h-28 {
  height: 2.8rem;
}

.h-32 {
  height: 3.2rem;
}

.h-36 {
  height: 3.6rem;
}

.h-40 {
  height: 4rem;
}

.h-44 {
  height: 4.4rem;
}

.h-48 {
  height: 4.8rem;
}

.h-52 {
  height: 5.2rem;
}

.h-56 {
  height: 5.6rem;
}

.h-60 {
  height: 6rem;
}

.h-64 {
  height: 6.4rem;
}

.h-68 {
  height: 6.8rem;
}

.h-76 {
  height: 7.6rem;
}

.h-80 {
  height: 8rem;
}

.h-88 {
  height: 8.8rem;
}

.h-92 {
  height: 9.2rem;
}

.h-96 {
  height: 9.6rem;
}

.h-128 {
  height: 12.8rem;
}

.h-136 {
  height: 13.6rem;
}

.h-160 {
  height: 16rem;
}

.h-192 {
  height: 19.2rem;
}

.h-200 {
  height: 20rem;
}

.h-216 {
  height: 21.6rem;
}

.h-224 {
  height: 22.4rem;
}

.h-256 {
  height: 25.6rem;
}

.h-288 {
  height: 28.8rem;
}

.h-384 {
  height: 38.4rem;
}

.h-400 {
  height: 40rem;
}

.h-512 {
  height: 51.2rem;
}

.h-680 {
  height: 68rem;
}

.h-auto {
  height: auto;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-10 {
  font-size: 1rem;
}

.text-11 {
  font-size: 1.1rem;
}

.text-12 {
  font-size: 1.2rem;
}

.text-13 {
  font-size: 1.3rem;
}

.text-14 {
  font-size: 1.4rem;
}

.text-15 {
  font-size: 1.5rem;
}

.text-16 {
  font-size: 1.6rem;
}

.text-17 {
  font-size: 1.7rem;
}

.text-18 {
  font-size: 1.8rem;
}

.text-20 {
  font-size: 2rem;
}

.text-24 {
  font-size: 2.4rem;
}

.text-28 {
  font-size: 2.8rem;
}

.text-32 {
  font-size: 3.2rem;
}

.text-36 {
  font-size: 3.6rem;
}

.text-40 {
  font-size: 4rem;
}

.text-48 {
  font-size: 4.8rem;
}

.text-60 {
  font-size: 6rem;
}

.text-64 {
  font-size: 6.4rem;
}

.text-68 {
  font-size: 6.8rem;
}

.text-72 {
  font-size: 7.2rem;
}

.text-xs {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 2.4rem;
}

.text-base {
  font-size: 1.6rem;
}

.text-xl {
  font-size: 2rem;
}

.text-2xl {
  font-size: 2.4rem;
}

.text-3xl {
  font-size: 3rem;
}

.text-4xl {
  font-size: 3.6rem;
}

.text-5xl {
  font-size: 4.8rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-4 {
  margin: 0.4rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 0.6rem;
}

.m-12 {
  margin: 1.2rem;
}

.m-16 {
  margin: 1.6rem;
}

.m-20 {
  margin: 2rem;
}

.m-24 {
  margin: 2.4rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}

.mx-28 {
  margin-left: 2.8rem;
  margin-right: 2.8rem;
}

.mx-40 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.mr-6 {
  margin-right: 0.6rem;
}

.ml-6 {
  margin-left: 0.6rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mr-12 {
  margin-right: 1.2rem;
}

.mb-12 {
  margin-bottom: 1.2rem;
}

.ml-12 {
  margin-left: 1.2rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mr-16 {
  margin-right: 1.6rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mr-20 {
  margin-right: 2rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.ml-20 {
  margin-left: 2rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.ml-24 {
  margin-left: 2.4rem;
}

.mt-28 {
  margin-top: 2.8rem;
}

.mb-28 {
  margin-bottom: 2.8rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mr-32 {
  margin-right: 3.2rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.ml-32 {
  margin-left: 3.2rem;
}

.mb-36 {
  margin-bottom: 3.6rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mb-64 {
  margin-bottom: 6.4rem;
}

.mt-auto {
  margin-top: auto;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-mt-8 {
  margin-top: -0.8rem;
}

.-mr-12 {
  margin-right: -1.2rem;
}

.-mt-24 {
  margin-top: -2.4rem;
}

.-mt-48 {
  margin-top: -4.8rem;
}

.max-h-48 {
  max-height: 4.8rem;
}

.max-h-52 {
  max-height: 5.2rem;
}

.max-h-160 {
  max-height: 16rem;
}

.max-h-200 {
  max-height: 20rem;
}

.max-h-320 {
  max-height: 32rem;
}

.max-h-360 {
  max-height: 36rem;
}

.max-h-384 {
  max-height: 38.4rem;
}

.max-h-400 {
  max-height: 40rem;
}

.max-h-512 {
  max-height: 51.2rem;
}

.max-h-full {
  max-height: 100%;
}

.max-w-56 {
  max-width: 5.6rem;
}

.max-w-60 {
  max-width: 6rem;
}

.max-w-80 {
  max-width: 8rem;
}

.max-w-84 {
  max-width: 8.4rem;
}

.max-w-88 {
  max-width: 8.8rem;
}

.max-w-92 {
  max-width: 9.2rem;
}

.max-w-96 {
  max-width: 9.6rem;
}

.max-w-128 {
  max-width: 12.8rem;
}

.max-w-136 {
  max-width: 13.6rem;
}

.max-w-160 {
  max-width: 16rem;
}

.max-w-192 {
  max-width: 19.2rem;
}

.max-w-200 {
  max-width: 20rem;
}

.max-w-216 {
  max-width: 21.6rem;
}

.max-w-224 {
  max-width: 22.4rem;
}

.max-w-256 {
  max-width: 25.6rem;
}

.max-w-288 {
  max-width: 28.8rem;
}

.max-w-320 {
  max-width: 32rem;
}

.max-w-360 {
  max-width: 36rem;
}

.max-w-384 {
  max-width: 38.4rem;
}

.max-w-400 {
  max-width: 40rem;
}

.max-w-512 {
  max-width: 51.2rem;
}

.max-w-640 {
  max-width: 64rem;
}

.max-w-680 {
  max-width: 68rem;
}

.max-w-xs {
  max-width: 32rem;
}

.max-w-sm {
  max-width: 48rem;
}

.max-w-md {
  max-width: 64rem;
}

.max-w-lg {
  max-width: 80rem;
}

.max-w-xl {
  max-width: 96rem;
}

.max-w-2xl {
  max-width: 112rem;
}

.max-w-3xl {
  max-width: 128rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen {
  max-width: 100vw;
}

.min-h-0 {
  min-height: 0;
}

.min-h-28 {
  min-height: 2.8rem;
}

.min-h-32 {
  min-height: 3.2rem;
}

.min-h-36 {
  min-height: 3.6rem;
}

.min-h-40 {
  min-height: 4rem;
}

.min-h-44 {
  min-height: 4.4rem;
}

.min-h-48 {
  min-height: 4.8rem;
}

.min-h-56 {
  min-height: 5.6rem;
}

.min-h-64 {
  min-height: 6.4rem;
}

.min-h-88 {
  min-height: 8.8rem;
}

.min-h-136 {
  min-height: 13.6rem;
}

.min-h-200 {
  min-height: 20rem;
}

.min-h-224 {
  min-height: 22.4rem;
}

.min-h-320 {
  min-height: 32rem;
}

.min-h-384 {
  min-height: 38.4rem;
}

.min-h-400 {
  min-height: 40rem;
}

.min-h-512 {
  min-height: 51.2rem;
}

.min-h-640 {
  min-height: 64rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-auto {
  min-height: auto;
}

.min-w-0 {
  min-width: 0;
}

.min-w-16 {
  min-width: 1.6rem;
}

.min-w-20 {
  min-width: 2rem;
}

.min-w-24 {
  min-width: 2.4rem;
}

.min-w-28 {
  min-width: 2.8rem;
}

.min-w-32 {
  min-width: 3.2rem;
}

.min-w-36 {
  min-width: 3.6rem;
}

.min-w-40 {
  min-width: 4rem;
}

.min-w-52 {
  min-width: 5.2rem;
}

.min-w-60 {
  min-width: 6rem;
}

.min-w-88 {
  min-width: 8.8rem;
}

.min-w-92 {
  min-width: 9.2rem;
}

.min-w-96 {
  min-width: 9.6rem;
}

.min-w-128 {
  min-width: 12.8rem;
}

.min-w-136 {
  min-width: 13.6rem;
}

.min-w-160 {
  min-width: 16rem;
}

.min-w-192 {
  min-width: 19.2rem;
}

.min-w-200 {
  min-width: 20rem;
}

.min-w-208 {
  min-width: 20.8rem;
}

.min-w-216 {
  min-width: 21.6rem;
}

.min-w-224 {
  min-width: 22.4rem;
}

.min-w-256 {
  min-width: 25.6rem;
}

.min-w-288 {
  min-width: 28.8rem;
}

.min-w-320 {
  min-width: 32rem;
}

.min-w-360 {
  min-width: 36rem;
}

.min-w-400 {
  min-width: 40rem;
}

.min-w-full {
  min-width: 100%;
}

.min-w-auto {
  min-width: auto;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-center {
  object-position: center;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 0.4rem;
}

.p-6 {
  padding: 0.6rem;
}

.p-8 {
  padding: 0.8rem;
}

.p-12 {
  padding: 1.2rem;
}

.p-16 {
  padding: 1.6rem;
}

.p-20 {
  padding: 2rem;
}

.p-24 {
  padding: 2.4rem;
}

.p-28 {
  padding: 2.8rem;
}

.p-32 {
  padding: 3.2rem;
}

.p-40 {
  padding: 4rem;
}

.p-60 {
  padding: 6rem;
}

.p-64 {
  padding: 6.4rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.py-28 {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
}

.px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem;
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
}

.px-40 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-44 {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem;
}

.py-68 {
  padding-top: 6.8rem;
  padding-bottom: 6.8rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-4 {
  padding-top: 0.4rem;
}

.pr-4 {
  padding-right: 0.4rem;
}

.pb-4 {
  padding-bottom: 0.4rem;
}

.pl-4 {
  padding-left: 0.4rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 0.6rem;
}

.pr-6 {
  padding-right: 0.6rem;
}

.pb-6 {
  padding-bottom: 0.6rem;
}

.pt-8 {
  padding-top: 0.8rem;
}

.pr-8 {
  padding-right: 0.8rem;
}

.pb-8 {
  padding-bottom: 0.8rem;
}

.pl-8 {
  padding-left: 0.8rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 1.2rem;
}

.pr-12 {
  padding-right: 1.2rem;
}

.pb-12 {
  padding-bottom: 1.2rem;
}

.pl-12 {
  padding-left: 1.2rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pr-16 {
  padding-right: 1.6rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pr-20 {
  padding-right: 2rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.pl-20 {
  padding-left: 2rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pt-28 {
  padding-top: 2.8rem;
}

.pr-28 {
  padding-right: 2.8rem;
}

.pb-28 {
  padding-bottom: 2.8rem;
}

.pl-28 {
  padding-left: 2.8rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pl-32 {
  padding-left: 3.2rem;
}

.pt-36 {
  padding-top: 3.6rem;
}

.pr-36 {
  padding-right: 3.6rem;
}

.pt-40 {
  padding-top: 4rem;
}

.pr-40 {
  padding-right: 4rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pl-44 {
  padding-left: 4.4rem;
}

.pt-48 {
  padding-top: 4.8rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.pr-56 {
  padding-right: 5.6rem;
}

.pb-56 {
  padding-bottom: 5.6rem;
}

.pl-68 {
  padding-left: 6.8rem;
}

.placeholder-gray-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-1 {
  right: 0.25rem;
}

.top-6 {
  top: 0.6rem;
}

.right-6 {
  right: 0.6rem;
}

.top-12 {
  top: 1.2rem;
}

.right-12 {
  right: 1.2rem;
}

.left-16 {
  left: 1.6rem;
}

.top-24 {
  top: 2.4rem;
}

.right-24 {
  right: 2.4rem;
}

.bottom-32 {
  bottom: 3.2rem;
}

.-top-16 {
  top: -1.6rem;
}

.-bottom-56 {
  bottom: -5.6rem;
}

.left-1\/2 {
  left: 50%;
}

.resize-y {
  resize: vertical;
}

.resize {
  resize: both;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow-0 {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-6 {
  --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-10 {
  --tw-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-12 {
  --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-transparent {
  color: transparent;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(227, 52, 47, var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(56, 193, 114, var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgba(52, 144, 220, var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgba(149, 97, 226, var(--tw-text-opacity));
}

.text-icon {
  color: rgba(0, 0, 0, 0.65);
}

.text-grey-darkest {
  --tw-text-opacity: 1;
  color: rgba(61, 72, 82, var(--tw-text-opacity));
}

.text-grey-darker {
  --tw-text-opacity: 1;
  color: rgba(96, 111, 123, var(--tw-text-opacity));
}

.text-grey-dark {
  --tw-text-opacity: 1;
  color: rgba(135, 149, 161, var(--tw-text-opacity));
}

.text-grey-light {
  --tw-text-opacity: 1;
  color: rgba(218, 225, 231, var(--tw-text-opacity));
}

.text-cardapio-web {
  --tw-text-opacity: 1;
  color: rgba(137, 0, 0, var(--tw-text-opacity));
}

.text-red-darkest {
  --tw-text-opacity: 1;
  color: rgba(59, 13, 12, var(--tw-text-opacity));
}

.text-red-darker {
  --tw-text-opacity: 1;
  color: rgba(98, 27, 24, var(--tw-text-opacity));
}

.text-red-dark {
  --tw-text-opacity: 1;
  color: rgba(204, 31, 26, var(--tw-text-opacity));
}

.text-red-light {
  --tw-text-opacity: 1;
  color: rgba(239, 87, 83, var(--tw-text-opacity));
}

.text-red-lighter {
  --tw-text-opacity: 1;
  color: rgba(249, 172, 170, var(--tw-text-opacity));
}

.text-orange-darkest {
  --tw-text-opacity: 1;
  color: rgba(70, 42, 22, var(--tw-text-opacity));
}

.text-orange-darker {
  --tw-text-opacity: 1;
  color: rgba(97, 59, 31, var(--tw-text-opacity));
}

.text-orange-dark {
  --tw-text-opacity: 1;
  color: rgba(222, 117, 31, var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgba(246, 153, 63, var(--tw-text-opacity));
}

.text-orange-light {
  --tw-text-opacity: 1;
  color: rgba(250, 173, 99, var(--tw-text-opacity));
}

.text-yellow-dark {
  --tw-text-opacity: 1;
  color: rgba(242, 208, 36, var(--tw-text-opacity));
}

.text-green-darkest {
  --tw-text-opacity: 1;
  color: rgba(15, 47, 33, var(--tw-text-opacity));
}

.text-green-darker {
  --tw-text-opacity: 1;
  color: rgba(26, 71, 49, var(--tw-text-opacity));
}

.text-green-dark {
  --tw-text-opacity: 1;
  color: rgba(31, 157, 85, var(--tw-text-opacity));
}

.text-green-light {
  --tw-text-opacity: 1;
  color: rgba(81, 216, 138, var(--tw-text-opacity));
}

.text-teal-dark {
  --tw-text-opacity: 1;
  color: rgba(56, 168, 157, var(--tw-text-opacity));
}

.text-teal-light {
  --tw-text-opacity: 1;
  color: rgba(100, 213, 202, var(--tw-text-opacity));
}

.text-blue-darkest {
  --tw-text-opacity: 1;
  color: rgba(18, 40, 58, var(--tw-text-opacity));
}

.text-blue-darker {
  --tw-text-opacity: 1;
  color: rgba(28, 61, 90, var(--tw-text-opacity));
}

.text-blue-dark {
  --tw-text-opacity: 1;
  color: rgba(39, 121, 189, var(--tw-text-opacity));
}

.text-blue-light {
  --tw-text-opacity: 1;
  color: rgba(108, 178, 235, var(--tw-text-opacity));
}

.text-indigo-dark {
  --tw-text-opacity: 1;
  color: rgba(86, 97, 179, var(--tw-text-opacity));
}

.text-indigo-light {
  --tw-text-opacity: 1;
  color: rgba(120, 134, 215, var(--tw-text-opacity));
}

.text-purple-darkest {
  --tw-text-opacity: 1;
  color: rgba(33, 24, 60, var(--tw-text-opacity));
}

.text-purple-darker {
  --tw-text-opacity: 1;
  color: rgba(56, 43, 95, var(--tw-text-opacity));
}

.text-purple-dark {
  --tw-text-opacity: 1;
  color: rgba(121, 74, 207, var(--tw-text-opacity));
}

.text-purple-light {
  --tw-text-opacity: 1;
  color: rgba(167, 121, 233, var(--tw-text-opacity));
}

.text-pink-dark {
  --tw-text-opacity: 1;
  color: rgba(235, 82, 134, var(--tw-text-opacity));
}

.text-pink-light {
  --tw-text-opacity: 1;
  color: rgba(250, 126, 168, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:text-red:hover {
  --tw-text-opacity: 1;
  color: rgba(227, 52, 47, var(--tw-text-opacity));
}

.hover\:text-red-darkest:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 13, 12, var(--tw-text-opacity));
}

.hover\:text-red-light:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 87, 83, var(--tw-text-opacity));
}

.hover\:text-green-darker:hover {
  --tw-text-opacity: 1;
  color: rgba(26, 71, 49, var(--tw-text-opacity));
}

.hover\:text-blue-dark:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 121, 189, var(--tw-text-opacity));
}

.text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.align-middle {
  vertical-align: middle;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.w-0 {
  width: 0;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 0.4rem;
}

.w-8 {
  width: 0.8rem;
}

.w-12 {
  width: 1.2rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 1.6rem;
}

.w-20 {
  width: 2rem;
}

.w-24 {
  width: 2.4rem;
}

.w-28 {
  width: 2.8rem;
}

.w-32 {
  width: 3.2rem;
}

.w-36 {
  width: 3.6rem;
}

.w-40 {
  width: 4rem;
}

.w-44 {
  width: 4.4rem;
}

.w-48 {
  width: 4.8rem;
}

.w-52 {
  width: 5.2rem;
}

.w-56 {
  width: 5.6rem;
}

.w-60 {
  width: 6rem;
}

.w-64 {
  width: 6.4rem;
}

.w-68 {
  width: 6.8rem;
}

.w-72 {
  width: 7.2rem;
}

.w-76 {
  width: 7.6rem;
}

.w-80 {
  width: 8rem;
}

.w-88 {
  width: 8.8rem;
}

.w-92 {
  width: 9.2rem;
}

.w-96 {
  width: 9.6rem;
}

.w-128 {
  width: 12.8rem;
}

.w-136 {
  width: 13.6rem;
}

.w-160 {
  width: 16rem;
}

.w-192 {
  width: 19.2rem;
}

.w-200 {
  width: 20rem;
}

.w-208 {
  width: 20.8rem;
}

.w-224 {
  width: 22.4rem;
}

.w-256 {
  width: 25.6rem;
}

.w-288 {
  width: 28.8rem;
}

.w-320 {
  width: 32rem;
}

.w-360 {
  width: 36rem;
}

.w-384 {
  width: 38.4rem;
}

.w-400 {
  width: 40rem;
}

.w-auto {
  width: auto;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-xs {
  width: 32rem;
}

.w-lg {
  width: 80rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.33333%;
}

.w-2\/3 {
  width: 66.66667%;
}

.w-1\/4 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.66667%;
}

.w-5\/6 {
  width: 83.33333%;
}

.w-8\/12 {
  width: 66.66667%;
}

.w-10\/12 {
  width: 83.33333%;
}

.w-11\/12 {
  width: 91.66667%;
}

.w-full {
  width: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-99 {
  z-index: 99;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.gap-4 {
  gap: 0.4rem;
}

.gap-6 {
  gap: 0.6rem;
}

.gap-8 {
  gap: 0.8rem;
}

.gap-12 {
  gap: 1.2rem;
}

.gap-16 {
  gap: 1.6rem;
}

.gap-20 {
  gap: 2rem;
}

.gap-24 {
  gap: 2.4rem;
}

.gap-28 {
  gap: 2.8rem;
}

.gap-32 {
  gap: 3.2rem;
}

.gap-36 {
  gap: 3.6rem;
}

.gap-48 {
  gap: 4.8rem;
}

.gap-60 {
  gap: 6rem;
}

.gap-72 {
  gap: 7.2rem;
}

.gap-x-8 {
  column-gap: 0.8rem;
}

.gap-x-16 {
  column-gap: 1.6rem;
}

.gap-x-32 {
  column-gap: 3.2rem;
}

.gap-y-8 {
  row-gap: 0.8rem;
}

.gap-y-12 {
  row-gap: 1.2rem;
}

.gap-y-20 {
  row-gap: 2rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-90 {
  --tw-rotate: -90deg;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.duration-700 {
  transition-duration: 700ms;
}

.delay-300 {
  transition-delay: 300ms;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes passingThrough {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }

  30%, 70% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}

@keyframes passingThroughBorderSuccess {
  30%, 70% {
    border-color: #51D88A;
  }
}

@keyframes passingThroughSuccess {
  15%, 45% {
    background-color: rgba(81,216,138, 0.5);
  }
}

@keyframes bounceOnce {
  0% {
    transform: translateY(0px);
  }

  15% {
    transform: translateY(-15px);
  }

  30% {
    transform: translateY(0px);
  }

  45% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-passingThrough {
  animation: passingThrough 5s cubic-bezier(0.77, 0, 0.175, 1);
}

.animate-passingThroughBorderSuccess {
  animation: passingThroughBorderSuccess 5s cubic-bezier(0.77, 0, 0.175, 1);
}

.animate-passingThroughSuccess {
  animation: passingThroughSuccess 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.hover\:line-clamp-none:hover {
  -webkit-line-clamp: unset;
}

@media (min-width: 600px) {
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse));
  }

  .sm\:rounded-8 {
    border-radius: .8rem;
  }

  .sm\:rounded-12 {
    border-radius: 1.2rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:flex-1 {
    flex: 1 1 0%;
  }

  .sm\:flex-auto {
    flex: 1 1 auto;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:h-32 {
    height: 3.2rem;
  }

  .sm\:h-36 {
    height: 3.6rem;
  }

  .sm\:h-48 {
    height: 4.8rem;
  }

  .sm\:h-56 {
    height: 5.6rem;
  }

  .sm\:h-60 {
    height: 6rem;
  }

  .sm\:h-72 {
    height: 7.2rem;
  }

  .sm\:h-136 {
    height: 13.6rem;
  }

  .sm\:h-288 {
    height: 28.8rem;
  }

  .sm\:h-320 {
    height: 32rem;
  }

  .sm\:h-360 {
    height: 36rem;
  }

  .sm\:h-400 {
    height: 40rem;
  }

  .sm\:h-640 {
    height: 64rem;
  }

  .sm\:text-15 {
    font-size: 1.5rem;
  }

  .sm\:text-16 {
    font-size: 1.6rem;
  }

  .sm\:text-17 {
    font-size: 1.7rem;
  }

  .sm\:text-18 {
    font-size: 1.8rem;
  }

  .sm\:text-20 {
    font-size: 2rem;
  }

  .sm\:text-24 {
    font-size: 2.4rem;
  }

  .sm\:text-28 {
    font-size: 2.8rem;
  }

  .sm\:text-36 {
    font-size: 3.6rem;
  }

  .sm\:m-32 {
    margin: 3.2rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .sm\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  .sm\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .sm\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }

  .sm\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-12 {
    margin-left: 1.2rem;
  }

  .sm\:ml-16 {
    margin-left: 1.6rem;
  }

  .sm\:mt-32 {
    margin-top: 3.2rem;
  }

  .sm\:ml-32 {
    margin-left: 3.2rem;
  }

  .sm\:max-h-512 {
    max-height: 51.2rem;
  }

  .sm\:max-w-80 {
    max-width: 8rem;
  }

  .sm\:max-w-128 {
    max-width: 12.8rem;
  }

  .sm\:max-w-200 {
    max-width: 20rem;
  }

  .sm\:max-w-224 {
    max-width: 22.4rem;
  }

  .sm\:max-w-256 {
    max-width: 25.6rem;
  }

  .sm\:max-w-320 {
    max-width: 32rem;
  }

  .sm\:max-w-512 {
    max-width: 51.2rem;
  }

  .sm\:max-w-640 {
    max-width: 64rem;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:min-w-48 {
    min-width: 4.8rem;
  }

  .sm\:min-w-136 {
    min-width: 13.6rem;
  }

  .sm\:min-w-160 {
    min-width: 16rem;
  }

  .sm\:min-w-192 {
    min-width: 19.2rem;
  }

  .sm\:min-w-288 {
    min-width: 28.8rem;
  }

  .sm\:min-w-512 {
    min-width: 51.2rem;
  }

  .sm\:min-w-auto {
    min-width: auto;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-4 {
    padding: 0.4rem;
  }

  .sm\:p-12 {
    padding: 1.2rem;
  }

  .sm\:p-16 {
    padding: 1.6rem;
  }

  .sm\:p-20 {
    padding: 2rem;
  }

  .sm\:p-24 {
    padding: 2.4rem;
  }

  .sm\:p-28 {
    padding: 2.8rem;
  }

  .sm\:p-32 {
    padding: 3.2rem;
  }

  .sm\:p-40 {
    padding: 4rem;
  }

  .sm\:p-48 {
    padding: 4.8rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .sm\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .sm\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .sm\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pt-4 {
    padding-top: 0.4rem;
  }

  .sm\:pr-8 {
    padding-right: 0.8rem;
  }

  .sm\:pt-12 {
    padding-top: 1.2rem;
  }

  .sm\:pb-12 {
    padding-bottom: 1.2rem;
  }

  .sm\:pl-12 {
    padding-left: 1.2rem;
  }

  .sm\:pt-16 {
    padding-top: 1.6rem;
  }

  .sm\:pr-16 {
    padding-right: 1.6rem;
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem;
  }

  .sm\:pt-20 {
    padding-top: 2rem;
  }

  .sm\:pb-20 {
    padding-bottom: 2rem;
  }

  .sm\:pl-20 {
    padding-left: 2rem;
  }

  .sm\:pt-24 {
    padding-top: 2.4rem;
  }

  .sm\:pr-24 {
    padding-right: 2.4rem;
  }

  .sm\:pb-24 {
    padding-bottom: 2.4rem;
  }

  .sm\:pl-24 {
    padding-left: 2.4rem;
  }

  .sm\:pl-28 {
    padding-left: 2.8rem;
  }

  .sm\:pt-32 {
    padding-top: 3.2rem;
  }

  .sm\:pr-56 {
    padding-right: 5.6rem;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:w-44 {
    width: 4.4rem;
  }

  .sm\:w-56 {
    width: 5.6rem;
  }

  .sm\:w-60 {
    width: 6rem;
  }

  .sm\:w-96 {
    width: 9.6rem;
  }

  .sm\:w-128 {
    width: 12.8rem;
  }

  .sm\:w-136 {
    width: 13.6rem;
  }

  .sm\:w-160 {
    width: 16rem;
  }

  .sm\:w-192 {
    width: 19.2rem;
  }

  .sm\:w-200 {
    width: 20rem;
  }

  .sm\:w-208 {
    width: 20.8rem;
  }

  .sm\:w-256 {
    width: 25.6rem;
  }

  .sm\:w-288 {
    width: 28.8rem;
  }

  .sm\:w-320 {
    width: 32rem;
  }

  .sm\:w-360 {
    width: 36rem;
  }

  .sm\:w-384 {
    width: 38.4rem;
  }

  .sm\:w-400 {
    width: 40rem;
  }

  .sm\:w-512 {
    width: 51.2rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-sm {
    width: 48rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.33333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:gap-12 {
    gap: 1.2rem;
  }

  .sm\:gap-16 {
    gap: 1.6rem;
  }

  .sm\:gap-20 {
    gap: 2rem;
  }

  .sm\:gap-24 {
    gap: 2.4rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: 960px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:rounded-8 {
    border-radius: .8rem;
  }

  .md\:border-1 {
    border-width: 1px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:flex-1 {
    flex: 1 1 0%;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:text-18 {
    font-size: 1.8rem;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mr-12 {
    margin-right: 1.2rem;
  }

  .md\:ml-24 {
    margin-left: 2.4rem;
  }

  .md\:max-h-256 {
    max-height: 25.6rem;
  }

  .md\:max-w-192 {
    max-width: 19.2rem;
  }

  .md\:max-w-md {
    max-width: 64rem;
  }

  .md\:min-h-384 {
    min-height: 38.4rem;
  }

  .md\:min-w-136 {
    min-width: 13.6rem;
  }

  .md\:min-w-160 {
    min-width: 16rem;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-16 {
    padding: 1.6rem;
  }

  .md\:p-24 {
    padding: 2.4rem;
  }

  .md\:p-32 {
    padding: 3.2rem;
  }

  .md\:p-40 {
    padding: 4rem;
  }

  .md\:p-48 {
    padding: 4.8rem;
  }

  .md\:p-60 {
    padding: 6rem;
  }

  .md\:p-160 {
    padding: 16rem;
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .md\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .md\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pt-8 {
    padding-top: 0.8rem;
  }

  .md\:pb-8 {
    padding-bottom: 0.8rem;
  }

  .md\:pt-160 {
    padding-top: 16rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-transparent {
    color: transparent;
  }

  .md\:w-128 {
    width: 12.8rem;
  }

  .md\:w-160 {
    width: 16rem;
  }

  .md\:w-200 {
    width: 20rem;
  }

  .md\:w-208 {
    width: 20.8rem;
  }

  .md\:w-224 {
    width: 22.4rem;
  }

  .md\:w-256 {
    width: 25.6rem;
  }

  .md\:w-288 {
    width: 28.8rem;
  }

  .md\:w-320 {
    width: 32rem;
  }

  .md\:w-384 {
    width: 38.4rem;
  }

  .md\:w-400 {
    width: 40rem;
  }

  .md\:w-512 {
    width: 51.2rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:gap-20 {
    gap: 2rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
}

@media (min-width: 1280px) {
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:text-4xl {
    font-size: 3.6rem;
  }

  .lg\:max-w-256 {
    max-width: 25.6rem;
  }

  .lg\:max-w-288 {
    max-width: 28.8rem;
  }

  .lg\:max-w-lg {
    max-width: 80rem;
  }

  .lg\:w-288 {
    width: 28.8rem;
  }

  .lg\:w-360 {
    width: 36rem;
  }

  .lg\:w-384 {
    width: 38.4rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-md {
    width: 64rem;
  }

  .lg\:w-1\/3 {
    width: 33.33333%;
  }

  .lg\:w-2\/3 {
    width: 66.66667%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: 1920px) {
  .xl\:table-cell {
    display: table-cell;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .\32xl\:table-cell {
    display: table-cell;
  }
}

@media print {
}
