.css-1k3cjop, .css-172spc3 {
    z-index: 1202 !important;
}

.css-xusf84 {
    z-index: 1201 !important;
}

.css-zaxvqe {
    padding-top: 8px !important;
}

.css-gb880y:hover, .css-1py1b57:hover {
    background-color: #9CA3AF !important;
}

.css-1dwhcai [data-grab-area]:enabled:hover [data-grab-area-line], .css-1dwhcai [data-grab-area]:enabled:active [data-grab-area-line], .css-1dwhcai [data-grab-area]:enabled:focus [data-grab-area-line] {
    background-color: #9CA3AF !important;
}

.css-3mje [data-grab-area]:enabled:hover [data-grab-area-line], .css-3mje [data-grab-area]:enabled:active [data-grab-area-line], .css-3mje [data-grab-area]:enabled:focus [data-grab-area-line] {
    background-color: #9CA3AF !important;
}

.css-1u84ut1 {
    background-color: #fff !important;
}