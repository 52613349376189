html {
  font-size: 50%;
  zoom: 1;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 480px) {}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) and (max-width: 650px) {}

/* Small Devices, Tablets */
@media only screen and (min-width: 650px) and (max-width: 850px) and (min-height: 700px) {
/*   html {
    zoom: 1.5;
  } */
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 850px) and (max-width: 1600px) {}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1600px) {
  html {
    font-size: 62.5%;
  }
}
