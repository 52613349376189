@import "normalize.css";
@import "responsiveness.css";

* {
  outline: none !important;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
}

body {
  font-size: 1.4rem;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 640px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #9ca3af;
    border-radius: 8px;
  }

  .hide-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
}

form label {
  z-index: 99;
}

.dropzone {
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: pointer;
  overflow: hidden;
}

.dropzone img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone p {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border-radius: 10px;
  font-size: large;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #666;
}

.dropzone p svg {
  color: #666;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.simple thead tr th:first-child {
  padding-left: 24px;
}

table.simple thead tr th:last-child {
  padding-right: 24px;
}

table.simple tbody tr td {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
  padding-left: 24px;
}

table.simple tbody tr td:last-child {
  padding-right: 24px;
}

table.simple tbody tr:last-child td {
  border-bottom: none;
}

table.simple.clickable tbody tr {
  cursor: pointer;
}

table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

table.dense {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.dense thead tr th {
  padding: 1.4rem 0.8rem;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.dense thead tr th:first-child {
  padding-left: 1.6rem;
}

table.dense thead tr th:last-child {
  padding-right: 1.6rem;
}

table.dense tbody tr td {
  padding: 1.4rem 0.8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.dense tbody tr td:first-child {
  padding-left: 1.6rem;
}

table.dense tbody tr td:last-child {
  padding-right: 1.6rem;
}

table.dense tbody tr:last-child td {
  border-bottom: none;
}

table.dense.clickable tbody tr {
  cursor: pointer;
}

table.dense.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@import "tailwind.css";

@media print {
  html,
  body {
    height: auto !important;
    overflow: initial !important;
    background: none;
  }
}
