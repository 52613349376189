.rs-picker-toggle-value {
  color: rgb(75, 85, 99) !important;
}

.rs-picker-toggle.rs-btn {
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle {
  border-radius: 0.6rem !important;
  border: 1px solid rgb(229, 231, 235) !important;
  background: rgb(249, 250, 251);
  color: rgb(75, 85, 99);
  font-weight: 700;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle.rs-picker-toggle-active {
  box-shadow: none;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle:hover {
  background: rgb(243, 244, 246);
}

.rs-picker-toggle.rs-btn.rs-btn-subtle > .rs-picker-toggle-textbox {
  cursor: pointer !important;
}

.rs-picker-toggle:hover {
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 6px !important;
}

.rs-picker-daterange .rs-picker-toggle-caret::before {
  content: "\f133" !important;
}

.rs-picker-menu {
  z-index: 2000;
}
